import React from "react";
import ContentBase from "./ContentBase";
import "./Documentation.css";

export default function Documentation() {
  return (
    <section className='Documentation-container'>
      <div className='mi-container'>
        <div className='docs-grid-layout'>
          <div className='docs-index'>
            <div className='doc-card'>
              <div className='doc-card-header'>
                <i class='fa-solid fa-clipboard-list mi-light'></i>
                <p className='mi-body f-500 mi-light'>Getting Started</p>
              </div>
              <div className='doc-card-body'>
                <div className='docs-link active'>
                  <p className='mi-note f-500'>API Access key</p>
                </div>
                <div className='docs-link'>
                  <p className='mi-note f-500'>API Response</p>
                </div>
                <div className='docs-link'>
                  <p className='mi-note f-500'>Available Endpoints</p>
                </div>
                <div className='docs-link'>
                  <p className='mi-note f-500'>Connecting via HTTPS</p>
                </div>
                <div className='docs-link'>
                  <p className='mi-note f-500'>JSONP Callbacks</p>
                </div>
                <div className='docs-link'>
                  <p className='mi-note f-500'>Error Codes</p>
                </div>
              </div>
            </div>

            <div className='doc-card'>
              <div className='doc-card-header'>
                <i class='fa-solid fa-clipboard-list mi-light'></i>
                <p className='mi-body f-500 mi-light'>Endpoints</p>
              </div>
              <div className='doc-card-body'>
                <div className='docs-link'>
                  <p className='mi-note f-500'>API Access key</p>
                </div>
                <div className='docs-link'>
                  <p className='mi-note f-500'>API Response</p>
                </div>
                <div className='docs-link'>
                  <p className='mi-note f-500'>Available Endpoints</p>
                </div>
                <div className='docs-link'>
                  <p className='mi-note f-500'>Connecting via HTTPS</p>
                </div>
                <div className='docs-link'>
                  <p className='mi-note f-500'>JSONP Callbacks</p>
                </div>
                <div className='docs-link'>
                  <p className='mi-note f-500'>Error Codes</p>
                </div>
              </div>
            </div>

            <div className='doc-card'>
              <div className='doc-card-header'>
                <i class='fa-solid fa-clipboard-list mi-light'></i>
                <p className='mi-body f-500 mi-light'>Options</p>
              </div>
              <div className='doc-card-body'>
                <div className='docs-link'>
                  <p className='mi-note f-500'>API Access key</p>
                </div>
                <div className='docs-link'>
                  <p className='mi-note f-500'>API Response</p>
                </div>
                <div className='docs-link'>
                  <p className='mi-note f-500'>Available Endpoints</p>
                </div>
                <div className='docs-link'>
                  <p className='mi-note f-500'>Connecting via HTTPS</p>
                </div>
                <div className='docs-link'>
                  <p className='mi-note f-500'>JSONP Callbacks</p>
                </div>
                <div className='docs-link'>
                  <p className='mi-note f-500'>Error Codes</p>
                </div>
              </div>
            </div>
          </div>
          <div className='doc-content'>
            <ContentBase />
          </div>
        </div>
      </div>
    </section>
  );
}
