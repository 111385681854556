import React from "react";
import ShowcaseImage from "../../../Assests/Files/images/showcase.png";
import { useNavigate } from "react-router-dom";
import "./Showcase.css";

export default function Showcase() {
  const navigate = useNavigate();
  return (
    <section className="Showcase_container">
      <div className="mi-container">
        <div className="Showcase_two_column_grid">
          <article className="Showcase_content_area">
            <div className="title-label">
              <hgroup className="mi-title-label variant-1">
                <h1 className="mi-note f-500">Welcome to TEZ FX</h1>
              </hgroup>
            </div>

            <div className="Showcase_content">
              <h1 className="mi-title f-700 mi-light">
                The easiest way to exchange cryptocurrencies on the blockchain.
              </h1>
              <p className="mi-subheading-lite mi-line-sm text-justify f-400 grey-200">
                Our mission is to make the entire Web3 ecosystem smarter and
                more impactful. With TEZ FX, we're empowering developers and
                microentrepreneurs/SMEs to leverage the benefits of blockchain
                technology for their businesses.
              </p>
              <div className="Showcase_action_btn">
                <button
                  onClick={() => navigate("/register")}
                  className="mi-btn mi-btn-md mi-btn-prime"
                >
                  Get Free API Key
                </button>
              </div>
            </div>
          </article>
          <figure className="Showcase_image_area">
            <img loading="lazy" src={ShowcaseImage} alt="ShowcaseImage" />
          </figure>
        </div>
      </div>
    </section>
  );
}
