import React from "react";
import "./About.css";
import Ico1 from "../../../Assests/Files/icons/ico1.png";
import Ico2 from "../../../Assests/Files/icons/ico2.png";
import Ico3 from "../../../Assests/Files/icons/ico3.png";
import Ico4 from "../../../Assests/Files/icons/ico4.png";
import Vector from "../../../Assests/Files/images/ui-vector.png";

export default function About() {
  return (
    <section className='About_container Vector-position'>
      <div className='mi-container'>
        <div className='About_mainCard'>
          <div className='About_card_wrapper'>
            <div className='About_card'>
              <img
                loading='lazy'
                src={Ico1}
                alt='Ico1'
                className='About_image'
              />
              <h1 className='mi-heading f-700 mi-light'>100+</h1>
              <p className='mi-subheading f-500 mi-light'>Api Integrations</p>
            </div>
            <div className='About_card'>
              <img
                loading='lazy'
                src={Ico2}
                alt='Ico1'
                className='About_image'
              />
              <h1 className='mi-heading f-700 mi-light'>250K</h1>
              <p className='mi-subheading f-500 mi-light'>Total Users</p>
            </div>
            <div className='About_card'>
              <img
                loading='lazy'
                src={Ico3}
                alt='Ico1'
                className='About_image'
              />
              <h1 className='mi-heading f-700 mi-light'>1M+</h1>
              <p className='mi-subheading f-500 mi-light'>Transactions</p>
            </div>
            <div className='About_card'>
              <img
                loading='lazy'
                src={Ico4}
                alt='Ico1'
                className='About_image'
              />
              <h1 className='mi-heading f-700 mi-light'>30+</h1>
              <p className='mi-subheading f-500 mi-light'>Team Members</p>
            </div>
          </div>
        </div>
      </div>
      <div className='vector-illustration right-postion'>
        <img src={Vector} alt='Vector' className='vector-image' />
      </div>
    </section>
  );
}
