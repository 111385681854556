import React from "react";
import Footer from "../../Shared/Footer/Footer";
import Header from "../../Shared/Header/Header";
import DocsBanner from "./DocsBanner/DocsBanner";
import Documentation from "./Documentation/Documentation";

export default function Docs() {
  return (
    <>
      <Header />
      <DocsBanner />
      <Documentation />
      <Footer />
    </>
  );
}
