import React from "react";

export default function Tab() {
  return (
    <div className='Tab_mainContainer'>
      <div className='Tab_cards active'>
        <i class='fa-solid fa-code'></i>
        <div className='mi-body f-500'>API Key</div>
      </div>
      <div className='Tab_cards'>
        <i class='fa-regular fa-paper-plane'></i>
        <div className='mi-body f-500'>Subscription</div>
      </div>
      <div className='Tab_cards'>
        <i class='fa-solid fa-wrench'></i>
        <div className='mi-body f-500'>Account</div>
      </div>
      <div className='Tab_cards'>
        <i class='fa-solid fa-chart-column'></i>
        <div className='mi-body f-500'>API Usage</div>
      </div>
      <div className='Tab_cards'>
        <i class='fa-solid fa-key'></i>
        <div className='mi-body f-500'>Tokens</div>
      </div>
    </div>
  );
}
