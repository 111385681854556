import React from "react";
import "./Main.css";

export default function Main() {
  return (
    <section className='Mainpage-container'>
      <div className='dashboard-content'>
        {/* <div className="dashboard-wrapper">
          <div className="wrapper-header">
            <i class="fa-solid fa-key"></i>
            <h1 className="mi-heading-lite f-500">Your API Access Key</h1>
          </div>
          <p className="mi-note">
            Your API access key is your unique authentication token used to gain
            access to the Statwig API.
          </p>
          <div className="access-key">
            <p className="key-text">d2121b3b908ded3157cdabc28db55ffd</p>
            <buuton className="key-reset">Reset</buuton>
          </div>
        </div> */}
        <h1 className='vl-heading f-700 mi-dark'>Dashboard Summary</h1>
        <div className='dashboard-wrapper '>
          <div className='dashboard-details-grid-three-column'>
            <div className='plan-card'>
              <div className='plan-banner'>
                <div className='plan-type'>
                  <p>Free</p>
                </div>
              </div>
              <div className='plan-body'>
                <div className='info-division'>
                  <div className='info-ico'>
                    <i class='fa-solid fa-check'></i>
                  </div>
                  <p className='mi-note f-400 grey-500'>Monthly Subscription</p>
                </div>
                <div className='info-division'>
                  <div className='info-ico'>
                    <i class='fa-solid fa-check'></i>
                  </div>
                  <p className='mi-note f-400 grey-500'>
                    0% (0 / 100) API Usage
                  </p>
                </div>
              </div>
            </div>
            <div className='personal-card'>
              <p className='mi-body sidebar-heading f-500'>Personal Details</p>
              <div className='info-division'>
                <i class='fa-solid fa-user grey-500'></i>
                <p className='mi-subheading-lite f-500 grey-500'>Arjun M</p>
              </div>
              <div className='info-division'>
                <i class='fa-solid fa-envelope grey-500'></i>
                <p className='mi-subheading-lite f-500 grey-500'>
                  imarjunmuthusamy@gmail.com
                </p>
              </div>
              <div className='info-division'>
                <i class='fa-sharp fa-solid fa-bell grey-500'></i>
                <p className='status-key fail-bg'>Unsubscribed</p>
              </div>
            </div>
            <div className='personal-card billing-card'>
              <p className='mi-body sidebar-heading f-500'>Billing Details</p>
              <div className='info-division'>
                <i class='fa-solid fa-calendar-days grey-500'></i>
                <p className='mi-subheading-lite f-500 grey-500'>
                  2022-10-01 - 2022-10-31
                </p>
              </div>
              <div className='info-division'>
                <i class='fa-solid fa-indian-rupee-sign grey-500'></i>
                <p className='mi-subheading-lite f-500 success'>$0.00</p>
              </div>
              <p className='mi-small grey-500'>No payment methods on file</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
