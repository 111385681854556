import React from "react";
import Footer from "../../Shared/Footer/Footer";
import Header from "../../Shared/Header/Header";
// import PriceBanner from "../Price/PriceBanner/PriceBanner";
import "./Dashboard.css";
import DashResults from "./DashResults/DashResults";
import Main from "./Main/Main";
import Tab from "./Tab/Tab";

export default function Dashboard() {
  return (
    <div className='page-colors'>
      <Header />
      {/* <PriceBanner /> */}
      <div className='mi-container'>
        <div className='Dashboard_Container'>
          <Main />
          <Tab />
          <DashResults />
        </div>

        {/* <div className="dashboard-grid-layout">
          <Sidebar />
          <Main />
        </div> */}
      </div>
      <Footer />
    </div>
  );
}
