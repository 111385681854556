import React from "react";

export default function DashResults() {
  return (
    <section className='Mainpage-container'>
      <div className='dashboard-content'>
        <div className='dashboard-wrapper-alt'>
          <div className='wrapper-header'>
            <i class='fa-solid fa-key'></i>
            <h1 className='mi-heading-lite f-500'>Your API Access Key</h1>
          </div>
          <p className='mi-note'>
            Your API access key is your unique authentication token used to gain
            access to the Statwig API.
          </p>
          <div className='access-key'>
            <p className='key-text'>d2121b3b908ded3157cdabc28db55ffd</p>
            <button className='key-reset'>Reset</button>
          </div>
        </div>
        <div className='Content-base-card'>
          <h1 className='mi-heading-lite f-500'>Getting Started</h1>
          <h1 className='mi-subheading f-500'>API Access Key</h1>
          <p className='mi-note f-400 mi-line-xs'>
            Your API access key is your unique authentication token used to gain
            access to the coinlayer API. You can find yours by logging in to
            your coinlayer account dashboard.
          </p>
          <p className='mi-note f-400 mi-line-xs'>
            In order to authenticate with the API, simply append the{" "}
            <span className='text-highlighted'>access_key</span>
            parameter to the API's base URL and set it to your API access key
            value.
          </p>
          <h1 className='mi-body f-500'>Base URL:</h1>
          <pre className='prettyprint white prettyprinted'>
            <span className='pln'>https</span>
            <span className='pun'>:</span>
            <span className='com'>//api.coinlayer.com/</span>
          </pre>
          <h1 className='mi-body f-500'>
            Append your API access key:
            <span className='mi-note f-400 mi-line-xs'>
              Here is an example API call illustrating how to authenticate with
              the coinlayer API:
            </span>
          </h1>
          <pre class='prettyprint white prettyprinted'>
            <a target='_blank' href='/signup/basic' class='run signup'>
              <span class='typ'>Run</span>
              <span class='pln'> API </span>
              <span class='typ'>Request</span>
            </a>
            <span class='typ'>https</span>
            <span class='pun'>:</span>
            <span class='com'>//api.coinlayer.com/live</span>
            <br />
            <span class='pln'></span>
            <span class='orange'>
              <span class='pun'>?</span>
              <span class='pln'> access_key </span>
              <span class='pun'>=</span>
              <span class='pln'> YOUR_ACCESS_KEY</span>
            </span>
          </pre>
        </div>
      </div>
    </section>
  );
}
