import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Docs from "./Pages/Docs/Docs";
import Landing from "./Pages/Landing/Landing";
import Price from "./Pages/Price/Price";
import Login from "./Pages/Login/Login";
import Registration from "./Pages/Registration/Registration";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' exact element={<Landing />} />
        <Route path='/login' exact element={<Login />} />
        <Route path='/register' exact element={<Registration />} />
        <Route path='/docs' exact element={<Docs />} />
        <Route path='/price' exact element={<Price />} />
        <Route path='/home' exact element={<Dashboard />} />
      </Routes>
    </BrowserRouter>
  );
}
