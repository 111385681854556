import React from "react";
import Footer from "../../Shared/Footer/Footer";
import Header from "../../Shared/Header/Header";
import "./Price.css";
import PriceBanner from "./PriceBanner/PriceBanner";
import PriceTable from "./PriceTable/PriceTable";

export default function Price() {
  return (
    <>
      <Header />
      <PriceBanner />

      <PriceTable />
      <Footer />
    </>
  );
}
