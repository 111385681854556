import React from "react";
import Blockcoin from "../../../Assests/Files/images/blockcoins.png";
import Bigvector from "../../../Assests/Files/images/benefit-vector.png";
import "./Benefits.css";

export default function Benefits() {
  return (
    <section className="Benefits_container mega_vector_postion">
      <div className="mi-container">
        <div className="Benefits_card_wrapper">
          <article className="Benefits_card_one_wrapper">
            <div className="Benefits_one_content_area">
              <div className="title-label">
                <hgroup className="mi-title-label variant-2">
                  <h1 className="mi-note f-500">Why Tez Fx ?</h1>
                </hgroup>
              </div>

              <div className="Benefits_content">
                <h1 className="mi-title f-700 mi-dark">
                  Benefits of Using Tez Fx
                </h1>
                <p className="mi-subheading-lite mi-line-sm f-500 mi-dark">
                  TEZ FX is a blockchain-powered exchange platform that aims to
                  make the entire Web3 ecosystem smarter and more impactful. Our
                  team of experienced developers and blockchain experts are
                  dedicated to empowering businesses and individuals to leverage
                  the power of blockchain technology. We believe that blockchain
                  has the potential to transform the global economy, and we're
                  committed to driving adoption and innovation in the space.
                  {/* <li>Multi-currency support</li>
                  <li>Local payment methods</li>
                  <li>Compliance</li>
                  <li>API or widget integration</li>
                  <li>User-friendly</li>
                  <li>Competitive rate</li> */}
                </p>
              </div>
            </div>
            <div className="Benefits_one_image_area">
              <img src={Blockcoin} alt="Blockcoin" />
            </div>
          </article>
          <article className="Benefits_card_two_wrapper">
            <div className="Benefits_two_image_area"></div>
            <div className="Benefits_two_content_area">
              <div className="title-label">
                <hgroup className="mi-title-label variant-1">
                  <h1 className="mi-note f-500">work transparently</h1>
                </hgroup>
              </div>

              <div className="Benefits_content">
                <div className="Benefits_solo_tile">
                  <div className="check_icon_wrapper">
                    <i class="fa-solid fa-check"></i>
                  </div>
                  <p className="mi-subheading-lite mi-line-sm f-500 mi-dark">
                    When compared to most major exchanges, the transaction fees
                    are the lowest and almost none of the transactions fail. It
                    helps you make transactions go smoothly at any time of day.
                    The transactions go quickly and easily
                  </p>
                </div>
                <div className="Benefits_solo_tile">
                  <div className="check_icon_wrapper">
                    <i class="fa-solid fa-check"></i>
                  </div>
                  <p className="mi-subheading-lite mi-line-sm f-500 mi-dark">
                    It enables web3 applications such as cryptocurrency
                    exchanges, wallets, and NFT markets, amongst others, to give
                    their customers the ability to convert fiat currency into
                    cryptocurrencies and vice versa using the available local
                    payment methods in a given area.
                  </p>
                </div>
                <div className="Benefits_solo_tile">
                  <div className="check_icon_wrapper">
                    <i class="fa-solid fa-check"></i>
                  </div>
                  <p className="mi-subheading-lite mi-line-sm f-500 mi-dark">
                    The integration is done using an rest API and the solution
                    takes care of the regulatory needs for KYC and AML.
                  </p>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
      <div className="mega_vector_illustration">
        <img src={Bigvector} alt="Bigvector" />
      </div>
    </section>
  );
}
