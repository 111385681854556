import { TextField } from "@mui/material";
import React, { useState } from "react";
import Logo from "../../Assests/Files/logo/logo.png";
import Bg from "../../Assests/Files/bg/bg.svg";
// import facebook from "../../Assests/Files/icons/facebook.svg";
// import google from "../../Assests/Files/icons/google.svg";
// import twitter from "../../Assests/Files/icons/twitter.svg";
import { Link, useNavigate } from "react-router-dom";
// import * as cActions from "../../redux/commonActions";

export default function Registration() {
  const navigate = useNavigate();
  const [layout, setlayout] = useState("2");
  // const register = async () => {
  //   await cActions.post('register', { "name": "Mahesh", "password": "test", "emailId": "mahesh@gmail.com", "organisation": "Test" }, false);
  // };
  return (
    <section className="Login-container">
      <div className="image-holder">
        <img src={Bg} alt="Bg" className="Bg-img" />
      </div>
      <div className="login-area-wrapper">
        <div className="login-form">
          {/* {layout === "1" && (
            <>
              <div className="login-header login-col-space">
                <h1 className="vl-heading f-700 mi-dark">
                  Register to account
                </h1>
                <p className="mi-body f-400 grey-400">
                  Access to the most powerfull tool in the crypto exchange.
                </p>
              </div>
              <div className="login-body login-col-space-md">
                <TextField
                  fullWidth
                  placeholder="Email Address"
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  placeholder="Password"
                  variant="outlined"
                  type="password"
                  autoComplete="current-password"
                />
                <div className="login-button-group">
                  <button
                    className="mi-btn mi-btn-md mi-btn-prime"
                    onClick={() => setlayout("2")}
                  >
                    Register
                  </button>
                </div>
              </div>
              <div className="login-quick login-col-space">
                <p className="mi-body f-500 mi-link mi-dark">Or Login with</p>
                <div className="login-options-list">
                  <div className="login-option-card">
                    <div className="login-icon-space">
                      <img src={facebook} alt="facebook" />
                    </div>
                    <p className="mi-note f-500 mi-link mi-dark">Facebook</p>
                  </div>
                  <div className="login-option-card">
                    <div className="login-icon-space">
                      <img src={google} alt="google" />
                    </div>
                    <p className="mi-note f-500 mi-link mi-dark">Google</p>
                  </div>
                  <div className="login-option-card">
                    <div className="login-icon-space">
                      <img src={twitter} alt="twitter" />
                    </div>
                    <p className="mi-note f-500 mi-link mi-dark">Twitter</p>
                  </div>
                </div>
              </div>
            </>
          )} */}

          {layout === "2" && (
            <>
              <div className="brand-logo-space mobile-header">
                <Link to="/" className="Header-logo">
                  <img src={Logo} alt="" />
                </Link>
              </div>
              <div className="login-header login-col-space">
                <h1 className="vl-heading f-700 mi-dark">
                  Register to account
                </h1>
                <p className="mi-body f-400 grey-400">
                  Access to the most powerful tool in the crypto exchange.
                </p>
              </div>
              <div className="login-body login-col-space-md">
                <TextField
                  fullWidth
                  placeholder="Email Address"
                  type="email"
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  placeholder="Password"
                  variant="outlined"
                  type="password"
                  autoComplete="current-password"
                />
                <TextField
                  fullWidth
                  placeholder="Company Name"
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  placeholder="Designation"
                  variant="outlined"
                />
                <div className="login-button-group">
                  <button
                    className="mi-btn mi-btn-md mi-btn-second"
                    onClick={() => navigate("/")}
                  >
                    Cancel
                  </button>
                  <button
                    className="mi-btn mi-btn-md mi-btn-prime"
                    onClick={() => setlayout("3")}
                  >
                    Register
                  </button>
                </div>
              </div>
            </>
          )}
          <Link to="/login" className="login-action login-col-space">
            <p className="mi-note f-400 grey-400">
              Already having Account?{" "}
              <span
                onClick={() => navigate("/login")}
                className="f-500 primary mi-link"
              >
                Login
              </span>
            </p>
          </Link>
        </div>
      </div>
    </section>
  );
}
