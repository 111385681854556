import React from "react";
import "./Header.css";
import Logo from "../../Assests/Files/logo/logo-alt.png";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  let location = useLocation();

  return (
    <header className='Header_container'>
      <div className='mi-container'>
        <nav className='Header_nav'>
          <figure className='brand_logo_wrapper'>
            <img loading='lazy' src={Logo} alt='logo' />
          </figure>
          <div className='Header_navRight'>
            <ul className='Header_navlist'>
              <li className='Header_navitem'>
                <Link to='/' className='Header_navlink'>
                  <p className='mi-note f-400 mi-link'>Home</p>
                </Link>
              </li>
              <li className='Header_navitem'>
                <Link to='/docs' className='Header_navlink'>
                  <p className='mi-note f-400 mi-link'>Docs</p>
                </Link>
              </li>
              <li className='Header_navitem'>
                <Link to='/price' className='Header_navlink'>
                  <p className='mi-note f-400 mi-link'>Price</p>
                </Link>
              </li>
            </ul>
            {location.pathname === "/home" ? (
              <article className='Header_button_wrapper'>
                <button
                  className='mi-btn mi-btn-md mi-btn-white'
                  onClick={() => navigate("/")}
                >
                  Logout
                </button>
              </article>
            ) : (
              <article className='Header_button_wrapper'>
                <button
                  className='mi-btn mi-btn-sm mi-btn-link'
                  onClick={() => navigate("/login")}
                >
                  Login
                </button>
                <button
                  className='mi-btn mi-btn-md mi-btn-white'
                  onClick={() => navigate("/register")}
                >
                  Sign Up
                </button>
              </article>
            )}
          </div>
          <div className='mobile_menu_icon'>
            <i class='fa-solid fa-bars mi-light'></i>
          </div>
        </nav>
      </div>
    </header>
  );
}
