import React from "react";

export default function ContentBase() {
  return (
    <div className='doc-content'>
      <div className='Content-base-card'>
        <h1 className='mi-heading-lite f-500'>Tez FX API</h1>
        <p className='mi-note f-400 mi-line-xs'>
          The Tez FX API is capable of delivering accurate exchange rate data
          for more than 385 cryptocurrencies in real-time. Crypto data is
          gathered from some of the largest cryptocurrency exchanges, requested
          using HTTP GET and returned in straightforward JSON format. Thanks to
          a refined fallback algorithm availability, consistency and reliability
          of crypto data returned by the Tez FX API are at the highest level.
        </p>
        <p className='mi-note f-400 mi-line-xs'>
          The API comes with a series of endpoints, functionalities and options.
          Find below a technical documentation containing descriptions,
          integration guides and examples in different programming languages.
        </p>
      </div>

      <div className='Content-base-card'>
        <h1 className='mi-subheading f-500'>Quickstart Tool</h1>
        <p className='mi-note f-400 mi-line-xs'>
          No time for a long read? Once signed up for a free or paid
          subscription plan you will be able to use our Quickstart Tool,
          allowing you to test any API endpoint at the click of a button.
        </p>
        <p className='mi-note f-400 mi-line-xs'>
          Get a free API access key to start using the Quickstart tool.
        </p>
        <div className='button-wrapper'>
          <button className='mi-btn mi-btn-md mi-btn-prime'>
            GET A FREE API KEY
          </button>
        </div>
      </div>

      <div className='Content-base-card'>
        <h1 className='mi-heading-lite f-500'>Getting Started</h1>
        <h1 className='mi-subheading f-500'>API Access Key</h1>
        <p className='mi-note f-400 mi-line-xs'>
          Your API access key is your unique authentication token used to gain
          access to the Tez FX API. You can find yours by logging in to your Tez
          FX account dashboard.
        </p>
        <p className='mi-note f-400 mi-line-xs'>
          In order to authenticate with the API, simply append the{" "}
          <span className='text-highlighted'>access_key</span>
          parameter to the API's base URL and set it to your API access key
          value.
        </p>
        <h1 className='mi-body f-500'>Base URL:</h1>
        <pre className='prettyprint white prettyprinted'>
          <span className='pln'>https</span>
          <span className='pun'>:</span>
          <span className='com'>//api.Tez FX.com/</span>
        </pre>
        <h1 className='mi-body f-500'>
          Append your API access key:
          <span className='mi-note f-400 mi-line-xs'>
            Here is an example API call illustrating how to authenticate with
            the Tez FX API:
          </span>
        </h1>
        <pre class='prettyprint white prettyprinted'>
          <a target='_blank' href='/signup/basic' class='run signup'>
            <span class='typ'>Sign</span>
            <span class='pln'> </span>
            <span class='typ'>Up</span>
            <span class='pln'> to </span>
            <span class='typ'>Run</span>
            <span class='pln'> API </span>
            <span class='typ'>Request</span>
          </a>
          <span class='typ'>https</span>
          <span class='pun'>:</span>
          <span class='com'>//api.tez.fx/live</span>
          <br />
          <span class='pln'></span>
          <span class='orange'>
            <span class='pun'>?</span>
            <span class='pln'> access_key </span>
            <span class='pun'>=</span>
            <span class='pln'> YOUR_ACCESS_KEY</span>
          </span>
        </pre>
      </div>
    </div>
  );
}
