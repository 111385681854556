import React from "react";
import "./Footer.css";
import Logo from "../../Assests/Files/logo/logo-alt.png";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="Footer_footer">
      <div className="mi-container">
        <div className="footer_grid_container">
          <div className="footer_brand">
            <figure className="brand_logo_wrapper_footer">
              <img loading="lazy" src={Logo} alt="logo" />
            </figure>
            {/* <p className="mi_body f-300 mi-light">
              Join thousands of teachers making a difference everyday
            </p> */}
            <div className="footer_social_media">
              <i className="fa-brands fa-square-facebook"></i>
              <i className="fa-brands fa-square-twitter"></i>
              <i className="fa-brands fa-linkedin"></i>
              <i className="fa-brands fa-square-gitlab"></i>
            </div>
          </div>
          <div className="footer_menu">
            <h1 className="mi-subheading footer_menu_text f-500 mi-light">
              COMPANY
            </h1>
            <div className="footer_menulist">
              <div className="footer_contact_card footer_link">
                <Link to="/" className="mi_body f-300">
                  About Us
                </Link>
              </div>
              <div className="footer_contact_card footer_link">
                <Link to="/" className="mi_body f-300">
                  Blog
                </Link>
              </div>
            </div>
          </div>
          <div className="footer_menu">
            <h1 className="mi-subheading footer_menu_text f-500 mi-light">
              LINKS
            </h1>
            <div className="footer_menulist">
              <div className="footer_contact_card footer_link">
                <Link to="/" className="mi_body f-300">
                  Events
                </Link>
              </div>
              <div className="footer_contact_card footer_link">
                <Link to="/" className="mi_body f-300">
                  FAQs
                </Link>
              </div>
            </div>
          </div>
          <div className="footer_menu">
            <h1 className="mi-subheading footer_menu_text f-500 mi-light">
              SUPPORT
            </h1>
            <div className="footer_menulist">
              <div className="footer_contact_card footer_link">
                <Link to="/" className="mi_body f-300">
                  Documentation
                </Link>
              </div>
              <div className="footer_contact_card footer_link">
                <Link to="/" className="mi_body f-300">
                  Forums
                </Link>
              </div>
              <div className="footer_contact_card footer_link">
                <Link to="/" className="mi_note f-300 mi_light">
                  Contact
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="Footer_copyrights">
          <p className="mi-body f-400 grey-200">
            &copy; All rights reserved{" "}
            <span className="mi-body f-500 mi-light"> Tez FX </span>
            Powered by{" "}
            <span className="mi-body f-500 mi-light"> Blockchain </span>
          </p>
        </div>
      </div>
    </footer>
  );
}
