import React from "react";

export default function PriceBanner() {
  return (
    <section className='Showcase_container'>
      <div className='mi-container'>
        <div className='Showcase_two_column_grid'>
          <article className='Showcase_content_area'>
            <div className='title-label'>
              <hgroup className='mi-title-label variant-4'>
                <h1 className='mi-note f-500'>
                  Best Tool in Crypto Conversion
                </h1>
              </hgroup>
            </div>

            <div className='Showcase_content'>
              <h1 className='mi-title f-700 mi-light'>
                We Offer Exciting and Cool Plans for the Users
              </h1>
              <div className='Showcase_action_btn'>
                <button className='mi-btn mi-btn-md mi-btn-prime'>
                  Check the Plans
                </button>
              </div>
            </div>
          </article>
          <figure className='Showcase_image_area'>
            {/* <img loading="lazy" src={ShowcaseImage} alt="ShowcaseImage" /> */}
          </figure>
        </div>
      </div>
    </section>
  );
}
