import React from "react";
import "./PriceTable.css";
import { useNavigate } from "react-router-dom";
import Vector from "../../../Assests/Files/images/ui-vector.png";

function PriceCard({ price, title, subtitle, btn, active }) {
  const navigate = useNavigate();
  return (
    <div className={`price-card ${active && "active"}`}>
      <div className='price-header'>
        <h1 className='mi-heading f-700'>{price}</h1>
        <p className='mi-small f-400 grey-500'>/month</p>
      </div>
      <div className='price-middle'>
        <h1 className='mi-subtitle f-500'>{title}</h1>
        <p className='mi-body f-400 grey-500'>{subtitle}</p>
      </div>

      <div className='pack-addon-list'>
        <div className='addon-card'>
          <div className='check-ico'>
            <i class='fa-solid fa-check'></i>
          </div>
          <p className='mi-body f-400 mi-grey-300'>100 requests / mo</p>
        </div>

        <div className='addon-card'>
          <div className='check-ico'>
            <i class='fa-solid fa-check'></i>
          </div>
          <p className='mi-body f-400 mi-grey-300'>Limited Support</p>
        </div>

        <div className='addon-card'>
          <div className='check-ico'>
            <i class='fa-solid fa-check'></i>
          </div>
          <p className='mi-body f-400 mi-grey-300'>Hourly Updates</p>
        </div>

        <div className='addon-card'>
          <div className='check-ico'>
            <i class='fa-solid fa-check'></i>
          </div>
          <p className='mi-body f-400 mi-grey-300'>Historical Data</p>
        </div>
        <div className='addon-card'>
          <div className='check-ico'>
            <i class='fa-solid fa-check'></i>
          </div>
          <p className='mi-body f-400 mi-grey-300'>USD Target Currency</p>
        </div>
      </div>

      <div className='pack-button'>
        <button
          className='mi-btn mi-btn-md btn-full mi-btn-prime'
          onClick={() => navigate("/register")}
        >
          {btn}
        </button>
      </div>
    </div>
  );
}

export default function PriceTable() {
  const Price = [
    {
      id: 1,
      price: "$0",
      title: "Free",
      subtitle: "For most businesses that want to otpimize web queries",
      btn: "Get a free key",
      active: false,
    },
    {
      id: 1,
      price: "$9.99",
      title: "Basic",
      subtitle: "For most businesses that want to otpimize web queries",
      btn: "Sign Up",
      active: true,
    },
    {
      id: 1,
      price: "$39.99",
      title: "Pro",
      subtitle: "For most businesses that want to otpimize web queries",
      btn: "Sign Up",
      active: false,
    },
    {
      id: 1,
      price: "$79.99",
      title: "Premium",
      subtitle: "For most businesses that want to otpimize web queries",
      btn: "Sign Up",
      active: false,
    },
  ];
  return (
    <div className='Price-list-container price_table_vector'>
      <div className='mi-container'>
        <div className='price-list-inner-container'>
          <div className='WorkingCard_title center_title_padding_top'>
            <div className='title-label'>
              <hgroup className='mi-title-label variant-1'>
                <h1 className='mi-note f-500'>CHOOSE YOUR BEST PLAN</h1>
              </hgroup>
            </div>

            <h1 className='mi-title f-700 mi-dark'>We’ve got you covered</h1>
          </div>
          <div className='price-list-body'>
            <div className='price-card-container'>
              {Price.map((list) => (
                <PriceCard
                  key={list.id}
                  price={list.price}
                  title={list.title}
                  subtitle={list.subtitle}
                  btn={list.btn}
                  active={list.active}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='vector-illustration price-left-postion'>
        <img src={Vector} alt='Vector' className='vector-image' />
      </div>
      <div className='vector-illustration price-right-postion'>
        <img src={Vector} alt='Vector' className='vector-image' />
      </div>
    </div>
  );
}
