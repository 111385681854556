import React from "react";
import "./Features.css";
import Web3 from "../../../Assests/Files/images/web3.png";
import s1 from "../../../Assests/Files/icons/s1.png";
import s2 from "../../../Assests/Files/icons/s2.png";
import s3 from "../../../Assests/Files/icons/s3.png";

function FeatureCard() {
  return (
    <article className="FeatureCard_container">
      <div className="FeatureCard_header_grid">
        <div className="FeatureCard_header_title">
          <div className="title-label">
            <hgroup className="mi-title-label variant-3">
              <h1 className="mi-note f-500">Why Tez Fx ?</h1>
            </hgroup>
          </div>

          <div className="FeatureCard_content">
            <h1 className="mi-title f-700 mi-light">
              Convert with real-time analytics
            </h1>
          </div>
        </div>
        <div className="FeatureCard_header_points">
          <div className="points_card">
            <i class="fa-solid fa-circle-check mi-subheading"></i>
            <p className="mi-subheading f-500 mi-light">
              Multi-currency support.
            </p>
          </div>
          <div className="points_card">
            <i class="fa-solid fa-circle-check mi-subheading"></i>
            <p className="mi-subheading f-500 mi-light">
              Local payment methods.
            </p>
          </div>
          <div className="points_card">
            <i class="fa-solid fa-circle-check mi-subheading"></i>
            <p className="mi-subheading f-500 mi-light">
              API or widget integration.
            </p>
          </div>
          <div className="points_card">
            <i class="fa-solid fa-circle-check mi-subheading"></i>
            <p className="mi-subheading f-500 mi-light">
              User-friendly & Competitive rate.
            </p>
          </div>
        </div>
      </div>
      <div className="FeatureCard_body_grid">
        <div className="FeatureCard_body_content">
          <div className="FeatureCard_body_tile_card">
            <div className="tile_card_image">
              <img loading="lazy" src={s1} alt="s1" />
            </div>
            <p className="mi-subheading mi-line-sm f-400 mi-light">
              This makes it simple for Web3 applications to process
              cryptocurrency and traditional payment methods.
            </p>
          </div>
          <div className="FeatureCard_body_tile_card">
            <div className="tile_card_image">
              <img loading="lazy" src={s2} alt="s2" />
            </div>
            <p className="mi-subheading mi-line-sm f-400 mi-light">
              Even if you don’t know much about crypto calculations, you can
              easily and quickly understand your cryptocurrency's value in some
              simple steps.
            </p>
          </div>
          <div className="FeatureCard_body_tile_card">
            <div className="tile_card_image">
              <img loading="lazy" src={s3} alt="s3" />
            </div>
            <p className="mi-subheading mi-line-sm f-400 mi-light">
              Also, It has the best service layer for building Web3 apps. It is
              capable of handling complex calculations
            </p>
          </div>
        </div>
        <div className="FeatureCard_body_image">
          <img loading="lazy" src={Web3} alt="Web3" />
        </div>
      </div>
    </article>
  );
}

function WorkingCard() {
  return (
    <article className="WorkingCard_container">
      <div className="WorkingCard_title">
        <div className="title-label">
          <hgroup className="mi-title-label variant-3">
            <h1 className="mi-note f-500">How does it work</h1>
          </hgroup>
        </div>

        <h1 className="mi-title f-700 mi-light">4 easy steps to your goals</h1>
      </div>
      <div className="WorkingCard_grid">
        <div className="WorkingCard_tile">
          <div className="WorkingCard_tile_icon v1">
            <p className="mi-heading f-900 mi-dark">01</p>
          </div>
          <p className="mi-subheading f-500 mi-light">Set up your Account</p>
          <p className="mi-note f-400 grey-200">
            Define your plan, set up workflows, add team members.
          </p>
        </div>
        <div className="WorkingCard_tile">
          <div className="WorkingCard_tile_icon v2">
            <p className="mi-heading f-900 mi-dark">02</p>
          </div>
          <p className="mi-subheading f-500 mi-light">Documentation</p>
          <p className="mi-note f-400 grey-200">Go through the documentation</p>
        </div>
        <div className="WorkingCard_tile">
          <div className="WorkingCard_tile_icon v3">
            <p className="mi-heading f-900 mi-dark">03</p>
          </div>
          <p className="mi-subheading f-500 mi-light">Generate API Key</p>
          <p className="mi-note f-400 grey-200">Login and generate api key</p>
        </div>
        <div className="WorkingCard_tile">
          <div className="WorkingCard_tile_icon v4">
            <p className="mi-heading f-900 mi-dark">04</p>
          </div>
          <p className="mi-subheading f-500 mi-light">Thats it!!!</p>
          <p className="mi-note f-400 grey-200">Start using the app.</p>
        </div>
      </div>
    </article>
  );
}

export default function Features() {
  return (
    <section className="Features_container">
      <div className="mi-container">
        <div className="Features_inner_wrapper">
          <FeatureCard />
          <WorkingCard />
        </div>
      </div>
    </section>
  );
}
