import React from "react";
import Footer from "../../Shared/Footer/Footer";
import Header from "../../Shared/Header/Header";
import About from "./about/About";
import Benefits from "./benefits/Benefits";
// import FAQ from "./faq/FAQ";
import Features from "./features/Features";
import Showcase from "./showcase/Showcase";

export default function Landing() {
  return (
    <>
      <Header />
      <Showcase />
      <About />
      <Benefits />
      <Features />
      {/* <FAQ /> */}
      <Footer />
    </>
  );
}
